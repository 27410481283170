import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import NavbarHeaderModule from '../components/navbarHeader/NavbarHeaderModule'
import Footer from '../components/common/Footer'

import { clearToken, getToken } from './../helpers/userToken'
import {
  getUserAsync,
  resetUserDetails,
  selectGetUserAsyncStatus,
} from '../reducers/userSlice'

const InvestorLayout = () => {
  const token = getToken()
  const location = useLocation()
  const dispatch = useDispatch()
  const getUserStatus = useSelector(selectGetUserAsyncStatus)

  useEffect(() => {
    getUserStatus === 'idle' && token && dispatch(getUserAsync(token._id))
  }, [dispatch, getUserStatus])

  if (isEmpty(token)) {
    return <Navigate to="/" state={{ path: location.pathname }} />
  }

  if (getUserStatus === 'failed' || getUserStatus === 'unAuthorized') {
    dispatch(resetUserDetails())
    clearToken()
    return <Navigate to="/" state={{ path: location.pathname }} />
  }

  return (
    <>
      <NavbarHeaderModule />
      <Outlet />
      <Footer />
    </>
  )
}

export default InvestorLayout
