import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getStartUpsUserDetails, updateStartupDetails } from '../api/startUpApi'

const initialState = {
  data: {},
  status: 'idle',
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getStartUpsUserAsync = createAsyncThunk(
  'startupUser/getUser',
  async (userId) => {
    const data = await getStartUpsUserDetails(userId)
    // The value we return becomes the `fulfilled` action payload
    return data
  }
)

export const updateStarupUserAsync = createAsyncThunk(
  'startupuser/updateUser',
  async (startUpUserDetail) => {
    const data = await updateStartupDetails(startUpUserDetail)
    return data
  }
)

export const startupUserSlice = createSlice({
  name: 'startupUser',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStartUserDetails: (state, action) => {
      state.data = action.payload
    },
    resetStartUserDetails: () => {
      return initialState
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getStartUpsUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getStartUpsUserAsync.fulfilled, (state, action) => {
        state.status = 'completed'
        state.data = action.payload
      })
      .addCase(getStartUpsUserAsync.rejected, (state, action) => {
        if (action.error.message === 'Request failed with status code 403') {
          state.status = 'unAuthorized'
        } else {
          state.status = 'failed'
        }
      })
      .addCase(updateStarupUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateStarupUserAsync.fulfilled, (state, action) => {
        state.status = 'completed'
        state.data = action.payload
      })
      .addCase(updateStarupUserAsync.rejected, (state, action) => {
        if (action.error.message === 'Request failed with status code 403') {
          state.status = 'unAuthorized'
        } else {
          state.status = 'failed'
        }
      })
  },
})

export const { setStartUserDetails, resetStartUserDetails } =
  startupUserSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.user.data)`
export const selectStartUpUser = (state) => state.startupUser.data

export const selectGetStartUpUserAsyncStatus = (state) =>
  state.startupUser.status

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState())
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount))
//   }
// }

export default startupUserSlice.reducer
