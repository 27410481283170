import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Loader from './components/common/Loader'
import InvestorLayout from './layouts/InvestorLayout'
import StartUpsLayout from './layouts/StartUpLayout'
import RegistrationLayout from './layouts/RegistrationLayout'
import AdminLayout from './layouts/Adminlayout'

const LoginPage = lazy(() => import('./pages/Login'))
const RegistrationPage = lazy(() => import('./pages/registration/Registration'))

const InvestorHomePage = lazy(() => import('./pages/investor/Home'))
const StartupDetailsPage = lazy(() => import('./pages/investor/StartupDetails'))
const MyPortFolioPage = lazy(() => import('./pages/investor/MyPortfolio'))
const AdminLoginModule = lazy(() =>
  import('./pages/AdminLogin.js')
)
const AdminDashBoard = lazy(() =>
  import('./pages/admin/AdminDashboardModule.js')
)

const InvestorNetworkPage = lazy(() =>
  import('./pages/investor/InvestorNetwork')
)
const EnablerPage = lazy(() => import('./pages/investor/Enabler'))

const InvestorProfilePage = lazy(() =>
  import('./pages/investor/InvestorProfile')
)

const MyAccountpage = lazy(() => import('./pages/investor/MyAccount'))

const InvetorDetailspage = lazy(() =>
  import('./pages/admin/InvestorsDetails')
)

const InvestorSetupPage = lazy(() =>
  import('./pages/registration/InvestorSetup')
)

//----strat ups ------------//

const StartUpDashBoardPage = lazy(() =>
  import('./pages/startUps/StartUpDashboard')
)

const StartupsAccountPage = lazy(() =>
  import('./pages/startUps/StartUpAccount')
)

const StartUpSetupPage = lazy(() => import('./pages/startUps/StartUpSetup'))

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="register" element={<RegistrationPage />} />
          <Route path="login" element={<LoginPage />} />

          <Route path="/register" element={<RegistrationLayout />}>
            <Route path="investor" element={<InvestorSetupPage />} />
            <Route path="startup" element={<StartUpSetupPage />} />
          </Route>

          <Route path="/investor" element={<InvestorLayout />}>
            <Route path="home" element={<InvestorHomePage />} />
            <Route
              path="startup-details/:startup_id"
              element={<StartupDetailsPage />}
            />
            {/* <Route path="InvestorNetwok" element={<InvestorNetworkPage />} />
            <Route path="Enabler" element={<EnablerPage />} /> 
            <Route path="InvestorProfile" element={<InvestorProfilePage />} /> */}
            <Route path="myportfolio" element={<MyPortFolioPage />} />
            <Route path="account" element={<MyAccountpage />} />
          </Route>

          <Route path="/startup" element={<StartUpsLayout />}>
            <Route
              path="startup-details/:startup_id"
              element={<StartupDetailsPage />}
            />
            <Route path="dashboard" element={<StartUpDashBoardPage />} />
            <Route path="startup-account" element={<StartupsAccountPage />} />
          </Route>
          <Route path="/admin/login" element={<AdminLoginModule />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="dashboard" element={<AdminDashBoard />} />
            <Route
              path="investor-details/:investor_id"
              element={<InvetorDetailspage />}
            />
            <Route
              path="startup-account/:userId"
              element={<StartupsAccountPage />}
            />
            <Route
              path="startup-details/:startup_id"
              element={<StartupDetailsPage />}
            />
          </Route>
        </Routes>
      </Suspense>
      <ToastContainer />
    </Router>
  )
}

export default App
