import React from 'react'

import logo from '../../images/log.png'

import '../../styles/components/common/footer.scss'

export default function Footer() {
  return (
    <>
      <footer   className="container">
        <div   className="row">
          <div   className="col-xxl-5 col-xl-5 footer-logo-column">
            <img src={logo} alt="Faad logo" />
            <p>
              FAAD Network is an Venture Capital Firm which invests in early stage
              startups across various sectors & geographies.
            </p>
          </div>

          <div   className="col-xl-4 col-xxl-4 footer-column">
            <h3> Quick Links </h3>
            <ul>
              <li>
                <a href="https://www.faad.in/"> Home </a>
              </li>
              <li>
                <a href="https://www.faad.in/portfolio"> Portfolio </a>
              </li>
              <li>
                <a href="https://www.faad.in/startups"> Startups </a>
              </li>
              <li>
                <a href="https://www.faad.in/investors"> Investors </a>
              </li>
              <li>
                <a href="https://www.faad.in/partner"> Partners</a>
              </li>

              <li>
                <a href="https://www.faad.in/teams">About </a>
              </li>
              <li>
                <a href="https://www.faad.in/news"> Faad Times </a>
              </li>
              <li>
                <a href="https://www.faad.in/contact">Contact </a>
              </li>
              {/* <li>
                <a href="PrivacyPolicy">Terms of Use</a>
              </li> */}
              <li>
                <a href="https://www.faad.in/privacy-policy"> Privacy Policy </a>
              </li>
             
            </ul>
          </div>

          <div   className="col-xxl-3 col-xl-3 footer-column">
            <h3> Registered Office </h3>
            <p>
              115-F, Mayur Vihar, Phase 1, Pocket 1, New Delhi - 110091, Country -
              India
            </p>
            <ul   className="social-group">
              <li>
                <a href="https://www.facebook.com/FNPL2014">
                <i  className="fa-brands fa-facebook fa-2x"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/werfaad">
                <i  className="fa-brands fa-twitter fa-2x"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/faad-in/">
                <i  className="fa-brands fa-linkedin fa-2x"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/werfaad">
                <i  className="fa-brands fa-instagram fa-2x"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@faadnetwork9057">
                  <i   className="fa-brands fa-youtube fa-2x"></i>
                </a>
              </li>
              {/* TODO:// add original icon */}
            </ul>
          </div>

        </div>
        <hr />
        <div   className="disclaimer">
          <p>
            <strong> DISCLAIMER: </strong> Faad.in is NOT a stock exchange
            recognised by the Securities Exchange Board of India (SEBI) under
            the Securities Contract (Regulation) Act, 1956. The securities
            offered by any company registered on this platform are not traded on
            any stock exchange recognised by SEBI.
          </p>
        </div>
        <p   className="copyright"> ©2023 by Faad Network Private Limited.`</p>
      </footer>
    </>
  );
}
