import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Container from 'react-bootstrap/Container'
import { Navbar, Nav } from 'react-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { resetUserDetails, selectUser } from '../../reducers/userSlice'
import {
  resetStartUserDetails,
  selectStartUpUser,
} from '../../reducers/startupUserSlice'
import { routes, StartupRoutes } from '../../helpers/constants'
import { clearToken } from '../../helpers/userToken'

import dommy from '../../images/dommy.png'
import logo from '../../images/log.png'

import '../../styles/components/navbarHeaderModule.scss'

const NavbarHeaderModule = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userDetail = useSelector(selectUser)

  const startUpUserDetail = useSelector(selectStartUpUser)

  const logout = (event) => {
    event.preventDefault()
    dispatch(resetUserDetails())
    clearToken()
    navigate('/')
  }

  const Startuplogout = (event) => {
    event.preventDefault()
    dispatch(resetStartUserDetails())
    clearToken()
    navigate('/')
  }

  const isStartupUser = !!startUpUserDetail.startup_id

  const isInvestorUser = !isEmpty(userDetail)

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary set-header-bottom p-0"
      //expanded={false}
      // data-bs-toggle="collapse"
      // data-bs-target="#navbarSupportedContent"
      // aria-controls="navbarSupportedContent"
      // aria-expanded="false"
      // aria-label="Toggle navigation"
    >
      <Container>
        <Navbar.Brand href="https://www.faad.in/" className="header-brand-logo">
          <img src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {isStartupUser && (
              <Nav.Link href={StartupRoutes.startUp.home}>My Start-Up</Nav.Link>
            )}
            {isInvestorUser && (
              <>
                <Nav.Link href={routes.investor.home}>Opportunities</Nav.Link>
                <Nav.Link href={routes.investor.myportfolio}>
                  My Portfolio
                </Nav.Link>
              </>
            )}

            <NavDropdown
              title={
                <span className="user-image">
                  <img
                    src={
                      startUpUserDetail.logo_url || userDetail.picture || dommy
                    }
                    className="user-pro-image"
                  />
                </span>
              }
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                href={
                  isStartupUser
                    ? StartupRoutes.startUp.startUpAccount
                    : isInvestorUser
                    ? routes.investor.account
                    : '#'
                }
                className="profile-content-set text-decoration-none"
              >
                <i className="fa-solid fa-user set-color-icon me-3"></i>
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/"
                onClick={isStartupUser ? Startuplogout : logout}
                className="profile-content-set text-decoration-none"
              >
                <i className="fa-solid fa-right-from-bracket set-color-icon me-3"></i>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarHeaderModule
