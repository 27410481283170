import axios from 'axios'

const logInStartUpUser = async (email) => {
  const response = await axios.post('startup/login', {
    email,
  })
  return response.data
}

const getStartUpsUserDetails = async (userId) => {
  const response = await axios.get(`startup/profile/${userId}`)
  return response.data
}

const getStatus = async (userId) => {
  const response = await axios.get(`startup/profile/${userId}/status`)
  return response.data
}

const updateStartupDetails = async ({
  startup_id,
  city,
  sector = [],
  startup_name,
  tagline,
  website,
  banner_type,
  banner_url,
  logo_url,
  video = {},
  video_url,
}) => {
  const response = await axios.patch(`startup/profile/${startup_id}`, {
    startup_id,
    city,
    sector,
    startup_name,
    tagline,
    website,
    banner_type,
    banner_url,
    logo_url,
    video,
    video_url,
  })

  return response.data
}

const updateStartupDetailsPitch = async ({ _id, startup_id, content }) => {
  const response = await axios.patch(
    `startup/profile/${startup_id}/pitch/${_id}`,
    {
      _id,
      startup_id,
      content,
    }
  )

  return response.data
}

const updateStartupDetailsFunding = async ({
  startup_id,
  cap_table_entry,
  commitments_so_far,
  funding_ask,
  minimum_ticket_size,
  valuation,
  currency_type,
  deadline,
  funding_type,
  funding_instrument,
  floor_value,
  cap_value,
  discounting_if_any,
}) => {
  const response = await axios.patch(`startup/profile/${startup_id}/funding`, {
    startup_id,
    cap_table_entry,
    commitments_so_far,
    funding_ask,
    minimum_ticket_size,
    valuation,
    currency_type,
    deadline,
    funding_type,
    funding_instrument,
    floor_value,
    cap_value,
    discounting_if_any,
  })

  return response.data
}

const updateStartupDetailsTeam = async ({
  startup_id,
  city,
  dob,
  education,
  email_id,
  experience,
  key,
  linked_in_url,
  name,
  phone,
  teamSize,
}) => {
  const response = await axios.post(`startup/profile/${startup_id}/teams`, {
    startup_id,
    city,
    dob,
    education,
    email_id,
    experience,
    key,
    linked_in_url,
    name,
    phone,
    teamSize,
  })

  return response.data
}

const updateStartupDetailsTeamEdit = async ({
  id,
  startup_id,
  key,
  linked_in_url,
  name,
  teamSize,
}) => {
  const response = await axios.patch(
    `startup/profile/${startup_id}/teams/${id}`,
    {
      id,
      startup_id,
      key,
      linked_in_url,
      name,
      teamSize,
    }
  )

  return response.data
}

const deleteStartupDetailsTeam = async ({ startup_id, key, _id }) => {
  const response = await axios.post(
    `startup/profile/${startup_id}/removeteams`,
    {
      key,
      _id,
    }
  )

  return response.data
}

const addStartupDetailsDocument = async ({ startup_id, data = {}, key }) => {
  const response = await axios.post(
    `startup/profile/${startup_id}/documents`,

    {
      startup_id,
      data,
      key,
    }
  )

  return response.data
}

const updateStartupDetailsDocument = async ({
  id,
  startup_id,
  data = {},
  key,
}) => {
  const response = await axios.patch(
    `startup/profile/${startup_id}/documents/${id}`,

    {
      id,
      startup_id,
      data,
      key,
    }
  )

  return response.data
}

const deleteStartupDetailsDocument = async ({ id, startup_id, data, key }) => {
  const response = await axios.patch(
    `startup/profile/${startup_id}/documents/removed/${id}`,

    {
      id,
      startup_id,
      data,
      key,
    }
  )

  return response.data
}

const updateStartupStatus = async ({ startup_id }) => {
  const response = await axios.patch(`startup/profile/${startup_id}/submit`)
  return response.data
}

const postQuarterlyReport = async ({ startup_id, name, url, description }) => {
  const response = await axios.post(`startup/quarterly-report/${startup_id}`, {
    name,
    url,
    description,
  })
  return response.data
}

const deleteQuarterlyReport = async ({ startup_id, url, id }) => {
  const response = await axios.patch(
    `startup/quarterly-report/${startup_id}/remove`,
    {
      url,
      id,
    }
  )
  return response.data
}


const postMonthlyReport = async ({ startup_id, name, url, description }) => {
  const response = await axios.post(`startup/monthly-report/${startup_id}`, {
    name,
    url,
    description,
  })
  return response.data
}

const deleteMonthlyReport = async ({ startup_id, url, id }) => {
  const response = await axios.patch(
    `startup/monthly-report/${startup_id}/remove`,
    {
      url,
      id,
    }
  )
  return response.data
}

const addHighlights = async ({ startup_id, value }) => {
  const response = await axios.post(`startup/highlights/${startup_id}`, {
    value,
  })
  return response.data
}

export {
  logInStartUpUser,
  getStartUpsUserDetails,
  getStatus,
  updateStartupDetails,
  updateStartupDetailsPitch,
  updateStartupDetailsFunding,
  updateStartupDetailsTeam,
  addStartupDetailsDocument,
  updateStartupDetailsDocument,
  deleteStartupDetailsDocument,
  updateStartupDetailsTeamEdit,
  deleteStartupDetailsTeam,
  updateStartupStatus,
  postQuarterlyReport,
  addHighlights,
  deleteQuarterlyReport,
  postMonthlyReport,
  deleteMonthlyReport
}
