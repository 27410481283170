import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import NavbarHeaderModule from '../components/navbarHeader/NavbarHeaderModule'
import Footer from '../components/common/Footer'

import { getToken } from './../helpers/userToken'

const RegistrationLayout = () => {
  const token = getToken()
  const location = useLocation()

  if (isEmpty(token)) {
    return <Navigate to="/register" state={{ path: location.pathname }} />
  }

  return (
    <>
      <NavbarHeaderModule />
      <Outlet />
      <Footer />
    </>
  )
}

export default RegistrationLayout
