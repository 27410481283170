import axios from 'axios'

const logInUser = async (email) => {
  const response = await axios.post('investor/login', {
    email,
  })

  return response.data
}

const getUserDetails = async (userId) => {
  const response = await axios.get(`investor/profile/${userId}`)
  return response.data
}

const getInvestorDetails = async (investor_id) => {
  const response = await axios.get(`investor/profile/${investor_id}`, {
    headers: { Authorization: 'Bearer admin' },
  })
  return response.data
}

const updateUserDetails = async ({
  investor_id,
  email,
  name,
  phone,
  phoneCountryCode,
  linked_in_url,
  facebook_url,
  twitter_url,
  country,
  state,
  city,
  pincode,
  activities,
  education,
  work_experience,
  associations,
  picture,
  bio,
}) => {
  const response = await axios.patch(`investor/profile/${investor_id}`, {
    investor_id,
    email,
    name,
    phone,
    phoneCountryCode,
    linked_in_url,
    facebook_url,
    twitter_url,
    country,
    state,
    city,
    pincode,
    activities,
    education,
    work_experience,
    associations,
    picture,
    bio,
  })

  return response.data
}

const updateUser = async ({ _id, email, name, picture }) => {
  const response = await axios.patch(`investor/${_id}`, {
    _id,
    email,
    name,
    picture,
  })

  return response.data
}

export {
  logInUser,
  getUserDetails,
  updateUserDetails,
  updateUser,
  getInvestorDetails,
}
