import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Footer from '../components/common/Footer'
import { getToken } from '../helpers/userToken'

const Adminlayout = () => {
  const token = getToken()
  const location = useLocation()

  if (isEmpty(token) || !token.isAdmin) {
    return <Navigate to="/" state={{ path: location.pathname }} />
  }
  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
}

export default Adminlayout
